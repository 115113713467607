import "tailwindcss/tailwind.css";
import { Amplify } from "aws-amplify";
import awsconfig from "../src/aws-exports";
import "styles/file-drop.css";
import "styles/tooltip.css";
import "styles/globals.css";
import "styles/Tailwindcssunreset.scss";
import "styles/pdf.css";
import "styles/draft-chart.css";
import { useEffect, useState } from "react";
import { appWithTranslation } from "next-i18next";
import { CookiesProvider } from "react-cookie";
import { UserbackProvider } from "@userback/react";
Amplify.configure(awsconfig);
const MyApp = ({ Component, pageProps }) => {
    const [name, setName] = useState("user");

    useEffect(() => {
        document.querySelector("body").classList.add("overflow-x-hidden");
    });

    useEffect(() => {
        document.querySelector("body").classList.add("sm:overflow-x-auto");
    });

    useEffect(() => {
        document.querySelector("body").classList.add("min-h-screen");
    });

    useEffect(() => {
        document.querySelector("body").classList.add("dark:bg-grey-dark");
    });

    useEffect(() => {
        document.getElementById("__next").classList.add("min-h-screen");
    });

    useEffect(() => {
        // Fetch the user's name from localStorage
        const token = JSON.parse(localStorage.getItem("rails_token"));
        if (token) {
            setName(token.data.name);
        }
    }, []);

    return (
        <CookiesProvider>
            <UserbackProvider
                token={process.env.NEXT_PUBLIC_USERBACK_TOKEN}
                options={{
                    user_data: {
                        id: "c41c6162-e3b2-4f34-a8b9-f8fad06dbaca",
                        info: {
                            name: name,
                        },
                    },
                }}
            >
                <Component {...pageProps} />
            </UserbackProvider>
        </CookiesProvider>
    );
};

export default appWithTranslation(MyApp);
